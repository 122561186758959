<template>
	<v-dialog
    v-model="dialogPapeleraEgresos.estatus"
    hide-overlay
    persistent
    max-width="1200"
  >
    <v-card>
      <v-card-title primary-title >
        Prospectos malos
      </v-card-title>
      <v-card-text >

      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
          <v-text-field
            v-model="search"
            dense
            single-line
            filled
            label="Buscar"
            append-icon="mdi-magnify"
          ></v-text-field>  
        </v-col>

        <v-data-table
          :headers="headers"
          :items="nomina"
          class="elevation-0"
          dense
          :search="search"
        >
        <template v-slot:item.actions="{ item }">
            <v-icon
              small
              @click="activarProspectoCerrado(item.idprospectos)"
              color="success"
            >
              mdi-check-outline
            </v-icon>
          </template> 
                    
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error"   dark @click="dialogPapeleraEgresos.estatus = false">Cancelar</v-btn>
      </v-card-actions>
      <v-btn 
        color="transparent elevation-0"
        x-small
        absolute
        top
        right
        fab
        class="mt-5 mr-0"
        @click="dialogPapeleraEgresos.estatus = false"
       ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
	export default {
		props:[
			'dialogPapeleraEgresos',
	  ],

    data: () => ({
    	nomina:[],
      headers: [
	      { text: "ID"              , value: "id", sortable: false },
	      { text: "Solicitó"        , value: "nombre_completo" },
	      { text: "Autorizó/Rechazó", value: "nombreAutorizo" },
	      { text: "Id Requisición"  , value: "id_requisicion_compra" },
	      { text: "Fecha"           , value: "fecha_partida" },
	      { text: "Ciclo"           , value: "ciclo" },
	      { text: "Elemento"        , value: "elemento_mantenimiento" },
	      { text: "Concepto"        , value: "concepto" },
	      { text: "Plantel"         , value: "plantel" },
	      { text: "Proyecto"        , value: "proyecto" },
	      { text: "Cantidad"        , value: "cantidad" },
	      { text: "Costo Unit."     , value: "costo_unitario" },
	      { text: "Total"           , value: "costo_total" },
	      { text: "Metodo de pago"  , value: "tipo_pago" },
	      { text: "Estatus"         , value: "estatus" },
	    ],
      search:'',
    }),

    async created() {
	    this.initialize()
	  },

	  methods:{
	  	initialize() {
      this.nomina = [];
      this.cargar = true;

      return this.$http.post("procesar.cambio.papelera").then((response) => {
        this.nomina = response.data;
        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },
	  }

	}
</script>

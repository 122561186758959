<template>
  <v-container fluid class="mt-4 ml-2 pr-6">
    <!--  -->
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">

          <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
          >
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                v-bind="attrs"
                text
                @click="snack = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <v-card-title class="text-subtitle-1">
            Egresos
            <v-spacer></v-spacer>

            <v-btn
              :disabled="!partidasSelected.length"
              class="mr-2"
              small
              color="primary"
              @click="obtenerSeleccionados"
            >
              Subir Comprobantes
            </v-btn>

            <v-btn color="primary" @click="initialize()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Consultar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Selecciona un ciclo</label>
                <v-autocomplete
                  label="Ciclo"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="ciclo"
                  :items="ciclos"
                  item-text="ciclo"
                  item-value="ciclo"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <label>Fecha inicio</label>
                <v-text-field
                  filled
                  single-line
                  label="Fecha inicio"
                  type="date"
                  hide-details
                  v-model="fecha_inicio"
                  dense
                  clearable
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label>Fecha final</label>
                <v-text-field
                  filled
                  single-line
                  label="Fecha final"
                  type="date"
                  hide-details
                  v-model="fecha_final"
                  dense
                  clearable
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Estatus</label>
                <v-select
                  label="Estatus"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="estatus"
                  :items="[
                    'Sin Comprobante',
                    'Con Comprobante',
                    'Autorizado',
                    'Rechazado',
                    'Pendiente Revisión',
                    'Sin Plantel',
                  ]"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Proyecto</label>
                <v-select
                  label="Proyecto"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="proyecto"
                  :items="proyectos"
                  item-text="proyecto"
                  item-value="proyecto"
                  clearable
                ></v-select>
              </v-col> </v-row
            ><br />

            
            <v-row align="center" class="no-gutters" justify="space-between">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  filled
                  single-line
                  label="Búscar"
                  append-icon="mdi-magnify"
                  hide-details
                  v-model="buscar"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- REQUISICIONES -->
            <v-data-table
              :headers="visibleHeaders"
              :items="filterNomina"
              dense
              show-select
              v-model="partidasSelected"
              class="elevation-0 mt-4"
              :search="buscar"
              item-class="clase_row"
            >

              <template v-slot:item.plantel_resultados="props">
                <v-edit-dialog
                  :return-value.sync="props.item.plantel_resultados"
                  @save="saveDialog( props.item )"
                  @cancel="cancelDialog"
                  @open="openDialog"
                  @close="closeDialog"
                >
                  {{ props.item.plantel_resultados }}
                  <template v-slot:input>
                    <v-autocomplete
                      :items="planteles"
                      v-model="props.item.id_plantel_resultados"
                      label="Plantel"
                      filled
                      dense
                      item-text="plantel"
                      item-value="id_plantel"
                    ></v-autocomplete>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.estatus="{ item }">
                <v-chip
                  color="green"
                  small
                  dark
                  v-if="item.estatus == 'Sin Estatus'"
                  >Sin Estatus</v-chip
                >
                <v-chip
                  color="red"
                  small
                  dark
                  v-if="item.estatus == 'Sin Comprobante'"
                  >Sin Comprobante</v-chip
                >
                <v-chip
                  color="orange"
                  small
                  dark
                  v-if="item.estatus == 'Con Comprobante'"
                  >Con Comprobante</v-chip
                >
                <v-chip
                  color="green"
                  small
                  dark
                  v-if="item.estatus == 'Autorizado'"
                  >Autorizado</v-chip
                >
                <v-chip
                  color="black"
                  small
                  dark
                  v-if="item.estatus == 'Rechazado'"
                  >Rechazado</v-chip
                >
              </template>

              <template v-slot:item.historial="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  color="blue"
                  @click="
                    (idfoto = item),
                      (dialog_mensaje_rechazo2 = true),
                      getMensajesRechazo(),
                      getHistorialEgreso()
                  "
                >
                  mdi-file-document
                </v-icon>
              </template>

              <template v-slot:item.imagen_pago="{ item }">
                <span v-for="(foto, i) in item.fotosPago" :key="i">
                  <v-icon
                    color="primary"
                    small
                    @click="
                      (idfoto = item),
                        verImagen(foto),
                        getMensajesRechazo(),
                        getComentarioComprobante()
                    "
                    v-if="extensionImagenes.includes(foto.extension)"
                  >
                    mdi-file-image
                  </v-icon>
                </span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  color="blue"
                  @click="(idtabla = item), (dialogSubirComprobante = true)"
                >
                  mdi-apple-keyboard-caps
                </v-icon>
              </template>

              <template v-slot:item.solicitar_cambio="{ item }">
                <v-icon
                  v-if="!item.idcambios_egresos"
                  small
                  class="mr-2"
                  color="black"
                  @click="egreso_modificar = item, dialogCambiarEgreso.estatus = true"
                >
                  mdi-pencil
                </v-icon>

                <v-icon
                  v-if="item.idcambios_egresos && [1,334, 623].includes( getdatosUsuario.iderp ) && !item.estatusSolicitud"
                  small
                  class="mr-2"
                  color="orange"
                  @click="egreso_modificar = item, dialogCambiarEgreso.estatus = true"
                >
                  mdi-eye
                </v-icon>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialgo para editar o ver la requisición -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ editedItem.id }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red" dark rounded small icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
    </v-dialog>

    <!-- Visualizar imagenes -->
    <v-dialog v-model="dialogImagen" max-width="850px" fullscreen>
      <v-card class="elevation-0">
        <v-toolbar color="primary" dark>
          Validar comprobante de pago
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            dark
            fab
            small
            class="mr-4"
            @click="anteriorPartida()"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn
            color="grey"
            dark
            fab
            small
            class="mr-12"
            @click="siguientePartida()"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <div class="pl-3 pt-4 black--text">
                Concepto: <b>$ {{ idfoto.concepto }}</b>
              </div>
              <v-list class="transparent" dense>
                <v-list-item class="pa-0">
                  <v-list-item-title align="end" class="pr-2">
                    Cantidad:
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="black--text font-weight-semibold text-body-1"
                  >
                    <b> {{ idfoto.cantidad }}</b>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item class="pa-0">
                  <v-list-item-title align="end" class="pr-2">
                    Costo Unitario:
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="black--text font-weight-semibold text-body-1"
                  >
                    <b>$ {{ formatNumber(idfoto.costo_unitario) }}</b>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item class="pa-0">
                  <v-list-item-title align="end" class="pr-2">
                    <b>Total:</b>
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="black--text font-weight-semibold text-body-1"
                  >
                    <b>$ {{ formatNumber(idfoto.costo_total) }}</b>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>


              <v-btn
                dark
                block
                @click="(dialogImagen = false), aceptarComprobante()"
                color="blue"
                >Autorizar</v-btn
              >
              <br />
              <v-btn
                dark
                block
                @click="(dialog_mensaje_rechazo = true), getMensajesRechazo()"
                color="red"
                >Rechazar</v-btn
              >

              <v-card>
                <v-card-title> </v-card-title>

                <v-card-text>
                  <v-row v-for="(item, index) in this.mensajes" :key="index">
                    <v-col cols="12">
                      <v-textarea
                        v-model="item.mensaje"
                        :id="'motivo_salida_' + index"
                        label="Motivo del rechazo"
                        outlined
                        hide-details
                        dense
                        auto-grow
                        readonly
                        style="max-width: 535px; margin-left: auto"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="item.respuesta"
                        :id="'motivo_salida_' + index"
                        label="Respuesta..."
                        outlined
                        hide-details
                        dense
                        auto-grow
                        readonly
                        style="max-width: 535px; margin-right: auto"
                        :class="{ 'd-none': !item.respuesta }"
                      ></v-textarea>
                    </v-col> </v-row
                  ><br />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="8"
              class="mt-2 text--center"
              align="center"
            >
              <v-card width="auto" max-width="950" @click="verImagen2(imagen)">
                <v-img
                  v-if="['BMP', 'GIF', 'jpg', 'jpeg', 'png', 'webp'].includes(imagen.extension) "
                  :src="url_servidor + imagen.name"
                  contain
                  max-width="100%"
                  max-height="500"
                  class="mt-2 text--center"
                  align="center"
                >
                </v-img>

                <embed
                  v-if="['PDF','pdf'].includes(imagen.extension) "
                  :src="url_servidor + imagen.name"
                  type="application/pdf"
                  style="width: 100%; height: 500px"
                />

              </v-card>
              <v-data-table
                :headers="headers3"
                :items="comentarios"
                dense
                v-model="partidasSelected"
                class="elevation-0 mt-4"
                style="max-width: 950px;"
              >
              </v-data-table>
              <v-textarea
                v-model="nota_comprobante"
                label="Notas..."
                outlined
                hide-details
                dense
                auto-grow
                style="max-width: 950px;"
              ></v-textarea>
            </v-col>         
          </v-row>
        </v-card-text>

        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          color="grey"
          dark
          fab
          class="elevation-6 mt-8"
          @click="dialogImagen = false"
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- SUBIR LOS ARCHIVOS -->
    <v-dialog
      v-model="dialogSubirComprobante"
      max-width="400px"
      @keydown="pegar"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ idtabla.id }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="
              (dialogSubirComprobante = false),
                (file = null),
                (vistaPrevia = null)
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text" align="center">
          <div v-if="!file">
            <div
              :class="['dropZone', dragging ? 'dropZone-over' : '']"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
            >
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title blue--text"
                  ><b>Arrastre el archivo para cargar</b></span
                >
                <div class="dropZone-upload-limit-info">
                  <div class="black--text">
                    Extensión: png, jpg, jpeg, svg, xls, xlsx, pdf
                  </div>
                  <div class="black--text">Tamaño máximo: 10 MB</div>
                </div>
              </div>
              <input type="file" @change="onChange" />
            </div>
          </div>

          <v-btn
            v-else
            color="red"
            class="mb-2"
            dense
            @click="removeFile"
            fab
            small
            dark
            ><v-icon>mdi-delete</v-icon></v-btn
          >
          <v-img
            :src="vistaPrevia.url"
            v-if="vistaPrevia && file.type.match('image.*')"
            contain
            max-height="300"
          />
          <v-btn
            color="orange"
            dark
            small
            block
            v-if="vistaPrevia && !file.type.match('image.*')"
            >Sin vista previa</v-btn
          >

          <v-card>
            <v-card-title>
              <span class="text-subtitle-1"
                ><b>Comentarios Comprobantes</b></span
              >
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.sueldo_1"
                    label="Sueldo 1"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.sueldo_2"
                    label="Sueldo 2"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.comisiones"
                    label="Comisiones"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.bonos"
                    label="Bonos"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.caja_de_ahorro"
                    label="Caja de Ahorro"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.deuda"
                    label="Deuda"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.a_depositar"
                    label="A Depositar"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.dep_1"
                    label="Dep 1"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.dep_2"
                    label="Dep 2"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>          
              <v-col cols="12">
              	<v-textarea
              		v-model="itemComprobante.notas"
              		outlined
              		dense
              		rows="3"
              		hide-details
              		label="Notas"
              	>	
              	</v-textarea>
              </v-col>
            </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block small v-if="file" @click="grabarDatos()"
            >Grabar datos</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SUBIR LOS ARCHIVOS -->
    <v-dialog
      v-model="dialogSubirComprobante2"
      max-width="400px"
      @keydown="pegar"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ idtabla.id }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="
              (dialogSubirComprobante2 = false),
                (file = null),
                (vistaPrevia = null)
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text" align="center">
          <div v-if="!file">
            <div
              :class="['dropZone', dragging ? 'dropZone-over' : '']"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
            >
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title blue--text"
                  ><b>Arrastre el archivo para cargar</b></span
                >
                <div class="dropZone-upload-limit-info">
                  <div class="black--text">
                    Extensión: png, jpg, jpeg, svg, xls, xlsx, pdf
                  </div>
                  <div class="black--text">Tamaño máximo: 10 MB</div>
                </div>
              </div>
              <input type="file" @change="onChange" />
            </div>
          </div>

          <v-btn
            v-else
            color="red"
            class="mb-2"
            dense
            @click="removeFile"
            fab
            small
            dark
            ><v-icon>mdi-delete</v-icon></v-btn
          >
          <v-img
            :src="vistaPrevia.url"
            v-if="vistaPrevia && file.type.match('image.*')"
            contain
            max-height="300"
          />
          <v-btn
            color="orange"
            dark
            small
            block
            v-if="vistaPrevia && !file.type.match('image.*')"
            >Sin vista previa</v-btn
          >
          <v-card>
            <v-card-title>
              <span class="text-subtitle-1"
                ><b>Comentarios Comprobantes</b></span
              >
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.sueldo_1"
                    label="Sueldo 1"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.sueldo_2"
                    label="Sueldo 2"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.comisiones"
                    label="Comisiones"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.bonos"
                    label="Bonos"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.caja_de_ahorro"
                    label="Caja de Ahorro"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.deuda"
                    label="Deuda"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.a_depositar"
                    label="A Depositar"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.dep_1"
                    label="Dep 1"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemComprobante.dep_2"
                    label="Dep 2"
                    filled
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>          
              <v-col cols="12">
              	<v-textarea
              		v-model="itemComprobante.notas"
              		outlined
              		dense
              		rows="3"
              		hide-details
              		label="Notas"
              	>	
              	</v-textarea>
              </v-col>
            </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            block
            small
            v-if="file"
            @click="subirComprobantesMultiples()"
            >Subir Comprobantes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVer"
      max-width="600px"
      v-if="imagen"
      persistent
      :fullscreen="fullscreen"
    >
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card
              height="100%"
              class="elevation-0"
              align="center"
              outlined
              v-if="imagen.extension != 'pdf'"
            >
              <v-img
                :src="url_servidor + imagen.name"
                contain
                aspect-ratio="2"
                v-if="fullscreen"
              ></v-img>

              <v-img
                :src="url_servidor + imagen.name"
                contain
                aspect-ratio="2"
                max-width="800"
                v-else
              ></v-img>
            </v-card>

            <v-card v-else>
              <embed :src="url_servidor + imagen.name" type="application/pdf" />
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn
            color="error"
            text
            class="mr-2"
            @click="fullscreen = true"
            v-if="!fullscreen"
            >Zoom</v-btn
          >
          <v-btn
            color="error"
            text
            class="mr-2"
            @click="fullscreen = false"
            v-else
            >Sin, Zoom</v-btn
          >
          <!-- Guardar la información  -->
          <v-btn
            color="primary"
            dark
            class="elevation-6"
            @click="dialogVer = false"
            >Okey</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Editar EGRESO -->
    <EditarEgreso 
      :egreso_modificar="egreso_modificar" 
      :dialogCambiarEgreso="dialogCambiarEgreso" 
      v-if="dialogCambiarEgreso.estatus" 
      :ciclos="ciclos" 
      @initialize="initialize"
    />

    <PapeleraEgresos  
      :dialogPapeleraEgresos="dialogPapeleraEgresos" 
      v-if="dialogPapeleraEgresos.estatus"
    />

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

import { Clipboard } from "@capacitor/clipboard";

// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import EditarEgreso    from "@/components/egresos/EditarEgrego"
import PapeleraEgresos from "@/components/egresos/PapelaraEgresos"

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default {
  components: {
    Alerta,
    carga,
    EditarEgreso,
    PapeleraEgresos
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    historialEgreso:[],

    dialog: false,
    dialogDelete: false,
    dialogVer: false,
    dialog_mensaje_rechazo: false,
    dialog_mensaje_rechazo2: false,
    dialog_mensaje_rechazo_multiple: false,
    dialogComprobantesMultiples: false,
    indexOf: [],
    proyectos: [],
    id_proyecto: 0,

    inversion: null,
    partidasFinales:[],

    egreso_modificar: null,
    dialogCambiarEgreso:{
      estatus: false,
    },
    dialogPapeleraEgresos:{
      estatus: false
    },

    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Id Requisición", value: "id_requisicion_compra" },
      { text: "Fecha", value: "fecha_partida" },
      { text: "Ciclo", value: "ciclo" },
      { text: "Elemento", value: "elemento_mantenimiento" },
      { text: "Categoría", value: "tipo_requisicion" },
      { text: "Concepto", value: "concepto" },
      { text: "Plantel", value: "plantel" },
      { text: "Plantel Estado", value: "plantel_resultados" },
      { text: "Proyecto", value: "proyecto" },
      { text: "Cuenta/Local", value: "numero_cuenta" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Costo Unit.", value: "costo_unitario" },
      { text: "Total", value: "costo_total" },
      { text: "Metodo de pago", value: "tipo_pago" },
      { text: "Estatus", value: "estatus" },
      { text: "Historial", value: "historial" },
      { text: "Comprobante", value: "imagen_pago" },
      { text: "Cambio", value: "solicitar_cambio", sortable: false },
      { text: "Subir Comprobante", value: "actions", sortable: false },
      
    ],

    headers4: [
      { text: "ID"                   , value: "id", sortable: false },
      { text: "Solicitó"             , value: "nombre_completo" },
      { text: "Autorizó/Rechazó"     , value: "nombreAutorizo" },
      { text: "Concepto"             , value: "concepto" },
      { text: "Proyecto"             , value: "proyecto" },
      { text: "Cantidad"             , value: "cantidad" },
      { text: "Costo Unit."          , value: "costo_unitario" },
      { text: "Total"                , value: "costo_total" },
      { text: "Tipo Actualización"   , value: "tipoActualizacion" },
      { text: "Evidencia"            , value: "evidencia" },
    ],

    headers2: [
      { text: "Concepto", value: "concepto", sortable: false },
      { text: "Ciclo", value: "ciclo" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Costo Unitario", value: "costo_unitario" },
      { text: "Total", value: "costo_total" },
      { text: "Estatus", value: "estatus" },
    ],

    headers3: [
      { text: "SUELDO 1", value: "sueldo_1" },
      { text: "SUELDO2", value: "sueldo_2" },
      { text: "COMISIONES", value: "comisiones" },
      { text: "BONOS", value: "bonos" },
      { text: "CAJA DE AHORRO", value: "caja_de_ahorro" },
      { text: "DEUDA", value: "deuda" },
      { text: "A DEPOSITAR", value: "a_depositar" },
      { text: "DEP 1", value: "dep_1" },
      { text: "DEP 2", value: "dep_2" },
    ],

    headersCiclosEgresos: [
        { text: 'ID'     , value: 'id_ciclo'     },
        { text: 'Ciclo'    , value: 'ciclo'       },
        { text: '# Egresos'    , value: 'Numero_Egresos'       },
        { text: 'Con Comprobante'    , value: 'Con_Comprobante'       },
        { text: 'Sin Comprobante'    , value: 'Sin_Comprobante'       },
        { text: 'Rechazados'    , value: 'Rechazado'       },
        { text: 'Autorizados'    , value: 'Autorizado'       },
        { text: 'Total'    , value: 'Total'       },

      ],

    headersPlanteEgreo: [
      { text: 'Plantel'       , value: 'plantel'     },
      { text: 'Monto'    , value: 'monto'       },
    ],

    egresosPlantel:[],

    partidasSelected: [],
    partidasSelected2: [],
    partidaFotos: [],

    nomina: [],
    opVisualizacion: 1,

    editedIndex: -1,

    editedItem: {
      id: "",
      nombre_completo: "",
      detalles: [],
      mensaje: "",
    },

    item: {
      respuesta: "",
      respuestas: "",
    },

    defaultItem: {
      idsolicitud_nomina: "",
      id_usuario: 0,
      idareas_ticket: 0,
    },

    itemComprobante: {
      id_partida: "",
      sueldo_1: "",
      sueldo_2: "",
      comisiones: "",
      caja_de_ahorro: "",
      a_depositar: "",
      dep_1: "",
      dep_2: "",
      deuda: "",
      bonos: "",
      notas: "",
    },

    itemComprobanteDefault: {
      id_partida: "",
      sueldo_1: "",
      sueldo_2: "",
      comisiones: "",
      caja_de_ahorro: "",
      a_depositar: "",
      dep_1: "",
      dep_2: "",
      deuda: "",
      bonos: "",
      notas: "",
    },

    file: "",
    dragging: false,
    vistaPrevia: null,
    autorizar: null,
    nota_rechazo: "",
    url: "",
    idtabla: [],
    idfoto: [],
    vueltas: 0,
    img: null,
    mensajes: [],
    respuestas: [],
    value: 0,
    result: [],
    porcentajeTotal: 100,
    cd1: 0,
    cd2: 0,
    cd3: 0,
    cd4: 0,
    cdt: 0,

    fecha_inicio: null,
    fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    buscar: "",

    dialogNuevaNomina: {
      estatus: false,
    },

    dialogSubirComprobante: false,
    dialogSubirComprobante2: false,
    dialogCiclosEgresos: false,
    archivos: null,
    dialogImagen: false,
    url_servidor: "",
    imagen: "",
    extensionImagenes: ["BMP", "GIF", "jpg", "jpeg", "png", "webp","pdf"],
    tipo: "Rechazado",
    tipo_pago: 0,
    dialogVer: false,
    fullscreen: false,
    img: null,
    comentarios: [],
    nota_comprobante: "",
    columnsVisible: true,

    ciclos: [],
    ciclo: null,
    estatus: null,
    proyecto: null,
    ciclosegresos: null,
    escuela: 1,
    visibleHeaders: [
      { text: "ID", value: "id", sortable: false },
      { text: "Id Requisición", value: "id_requisicion_compra" },
      { text: "Ciclo", value: "ciclo" },
      { text: "Elemento", value: "elemento_mantenimiento" },
      { text: "Categoría", value: "tipo_requisicion" },
      { text: "Concepto", value: "concepto" },
      { text: "Plantel", value: "plantel" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Costo Unit.", value: "costo_unitario" },
      { text: "Total", value: "costo_total" },
      { text: "Metodo de pago", value: "tipo_pago" },
      { text: "Estatus", value: "estatus" },
      { text: "Subir Comprobante", value: "actions", sortable: false },
    ],

    planteles:[],
    snack: false,
    snackColor: '',
    snackText: '',

    dialogEgresosPlantel: false,
    dialogPlanteles: false,
    id_plantelSeleccionado:null,
    partidasSelectedPlantel: [],
    search2: '',
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    filterNomina() {
      let data = this.nomina;

      if( this.inversion ){
        data = data.filter( el => { return el.ciclo ? el.ciclo.match('INVERS') : false })
      }

      if (this.ciclo) {
        data = data.filter((el) => el.ciclo == this.ciclo);
      }

      if (this.estatus) {

        if(this.estatus == 'Pendiente Revisión'){
          data = data.filter((el) => el.idcambios_egresos && !el.estatusSolicitud)
        }else if(this.estatus == 'Sin Plantel'){
          data = data.filter((el) => !el.plantel_resultados )
        }else{
          data = data.filter((el) => el.estatus == this.estatus);
        }

      }

      if (this.proyecto) {
        data = data.filter((el) => el.proyecto == this.proyecto);
      }

      return data;
    },

    filterCiclosEgresos() {
      let data = this.ciclosegresos;

      if (this.escuela) {
        data = data.filter((el) => el.escuela == this.escuela);
      }
      return data;
    },

    total() {
      let subtotal = this.nomina
        .map((item) => item.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal);
    },

    cantidadTotal() {
      let subtotal = this.nomina.length;
      this.cdt = subtotal;
      return Math.floor(subtotal);
    },

    totalPartidasMultiples() {
      let subtotal = this.partidaFotos
        .map((item) => item.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal);
    },

    cantidadPartidasMultiples() {
      let subtotal = this.partidaFotos.length;
      return Math.floor(subtotal);
    },

    totalComprobante() {
      this.nomina1 = this.nomina.filter(
        (objeto1) => objeto1.estatus == "Con Comprobante"
      );
      let subtotal1 = this.nomina1
        .map((item1) => item1.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal1);
    },

    cantidadComprobante() {
      this.nomina1 = this.nomina.filter(
        (objeto1) => objeto1.estatus == "Con Comprobante"
      );
      let subtotal1 = this.nomina1.length;
      this.cd1 = subtotal1;
      return Math.floor(subtotal1);
    },

    porcentajeComprobante() {
      let subtotal1 = (this.cd1 / this.cdt) * 100;
      const resultadoRedondeado = subtotal1.toFixed(2);
      return parseFloat(resultadoRedondeado);
    },

    totalSinComprobante() {
      this.nomina2 = this.nomina.filter(
        (objeto2) => objeto2.estatus == "Sin Comprobante"
      );
      let subtotal2 = this.nomina2
        .map((item2) => item2.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal2);
    },

    cantidadSinComprobante() {
      this.nomina2 = this.nomina.filter(
        (objeto2) => objeto2.estatus == "Sin Comprobante"
      );
      let subtotal2 = this.nomina2.length;
      this.cd2 = subtotal2;
      return Math.floor(subtotal2);
    },

    porcentajeSinComprobante() {
      let subtotal2 = (this.cd2 / this.cdt) * 100;
      const resultadoRedondeado = subtotal2.toFixed(2);
      return parseFloat(resultadoRedondeado);
    },

    totalRechazado() {
      this.nomina4 = this.nomina.filter(
        (objeto4) => objeto4.estatus == "Rechazado"
      );
      let subtotal4 = this.nomina4
        .map((item4) => item4.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal4);
    },

    cantidadRechazado() {
      this.nomina4 = this.nomina.filter(
        (objeto4) => objeto4.estatus == "Rechazado"
      );
      let subtotal4 = this.nomina4.length;
      this.cd3 = subtotal4;
      return Math.floor(subtotal4);
    },

    porcentajeRechazado() {
      let subtotal4 = (this.cd3 / this.cdt) * 100;
      const resultadoRedondeado = subtotal4.toFixed(2);
      return parseFloat(resultadoRedondeado);
    },

    totalAutorizado() {
      this.nomina3 = this.nomina.filter(
        (objeto3) => objeto3.estatus == "Autorizado"
      );
      let subtotal3 = this.nomina3
        .map((item3) => item3.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal3);
    },

    cantidadAutorizado() {
      this.nomina3 = this.nomina.filter(
        (objeto3) => objeto3.estatus == "Autorizado"
      );
      let subtotal3 = this.nomina3.length;
      this.cd4 = subtotal3;
      return Math.floor(subtotal3);
    },

    porcentajeAutorizado() {
      let subtotal3 = (this.cd4 / this.cdt) * 100;
      const resultadoRedondeado = subtotal3.toFixed(2);
      return parseFloat(resultadoRedondeado);
    },

    totalSinPlantel( ){

      return this.nomina.filter( el => !el.plantel_resultados ).length;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    partidasSelected( newSelection, oldSelection ){
      // Comparar los arreglos para encontrar elementos agregados o eliminados
      const addedItems = newSelection.filter(item => !oldSelection.includes(item));
      const removedItems = oldSelection.filter(item => !newSelection.includes(item));

      if (addedItems.length > 0) {
        console.log('Elementos agregados:', addedItems);
        this.partidasFinales.push( addedItems[0].id )
      }

      if (removedItems.length > 0) {
        console.log('Elementos eliminados:', removedItems);
        this.partidasFinales = this.partidasFinales.filter( el => { return el.id != removedItems[0].id })
      }

      for( const i in this.partidasFinales ){
        console.log( this.partidasFinales[i] )
      }

    }
  },

  async created() {
    this.url = axios.defaults.baseURL + "justificantes/";
    this.url_servidor = axios.defaults.baseURL + "fotos-egresos/";

    this.getPlanteles()
    this.getCiclos();
    await this.getProyectos();
  },

  methods: {

    getPlanteles () {
      this.cargar = true
      this.planteles = []
      return this.$http.get('planteles.list.erp').then(response=>{
        this.planteles = response.data
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    initialize() {
      this.nomina = [];
      this.cargar = true;

      const payload = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
        ciclo: this.ciclo,
      };

      return this.$http
        .post("consultar.partidas.egreso", payload)
        .then((response) => {
          this.nomina = response.data;
          this.cargar = false;

          if (
            this.getdatosUsuario.iderp == 1 ||
            this.getdatosUsuario.iderp == 623
          ) {
            if (this.vueltas == 0) {
              this.headers = this.headers.slice(0, -1);
              this.vueltas = 1;
            }
          }

          //if (this.getdatosUsuario.iderp == 1449) {
          //    this.nomina = this.nomina.filter((el) => el.elemento_mantenimiento !== "nomina administrativa");
          //}

          // if (this.getdatosUsuario.iderp == 1 || this.getdatosUsuario.iderp == 623){
          //  this.nomina = this.nomina.filter(function(prueba) {return prueba.estatus == 'Autorizado' || prueba.estatus === 'Con Comprobante' ;});
          // }

          // Validar si ya hay información, para poder subir el comprobante o mostrarlos
          if (this.editedIndex > -1) {
            const header = this.nomina.find(
              (el) => el.id == this.editedItem.id
            );

            this.editedItem = nomina;
          }
          if (this.dialogComprobantesMultiples) {
            const result = this.nomina.filter((el) => {
              return el.nombre_foto === this.idfoto.nombre_foto;
            });

            if (result.length > 1) {
              this.dialogComprobantesMultiples = true;
              this.result = result;
            } else {
              this.dialogImagen = true;
              this.dialogComprobantesMultiples = false;
            }
          }

          this.nomina = this.nomina.filter( el => el.id_usuario_solicita == this.getdatosUsuario.iderp )

          console.log( this.nomina[0] )


        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    format( valor ){
      return valor.toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN'
      });
    },

    getCiclos() {
      this.cargar = true;
      this.ciclos = [];
      return this.$http
        .get("ciclos.activos.erp")
        .then((response) => {
          this.ciclos = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.nomina.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.autorizar = null;
      this.nota_rechazo = "";
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    grabarDatos() {
      var formData = new FormData();
      let b = this.idtabla.id;
      const ids = b;

      formData.append("file", this.file);
      formData.append("requi", ids);
      formData.append("id_partida", this.idtabla.id);
      formData.append("sueldo1", this.itemComprobante.sueldo_1);
      formData.append("sueldo2", this.itemComprobante.sueldo_2);
      formData.append("comisiones", this.itemComprobante.comisiones);
      formData.append("caja_de_ahorro", this.itemComprobante.caja_de_ahorro);
      formData.append("a_depositar", this.itemComprobante.a_depositar);
      formData.append("dep_1", this.itemComprobante.dep_1);
      formData.append("dep_2", this.itemComprobante.dep_2);
      formData.append("deuda", this.itemComprobante.deuda);
      formData.append("bonos", this.itemComprobante.bonos);
      formData.append("notas", this.itemComprobante.notas);
      // formData.append("id_usuario_subio"  , this.getdatosUsuario.iderp );
      // formData.append("tipo_pago"         , this.tipo_pago );
      this.cargar = true;

      return this.$http
        .post("subir.comprobantes.partidas", formData)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.file = null;
          this.partidasSelected = [];
          this.vistaPrevia = null;
          this.cargar = false;
          this.dialogSubirComprobante = false;
          this.itemComprobante = Object.assign({}, this.itemComprobanteDefault);
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    verImagen(imagen) {
      console.log( imagen )
      this.imagen = imagen;
      this.partidasSelected2 = [];
      const result = this.nomina.filter((el) => {
        return el.nombre_foto === this.idfoto.nombre_foto;
      });

      this.getAllPartidas()

      // if (this.partidaFotos.length > 1) {
      //   this.dialogComprobantesMultiples = true;
      // } else {
      //   this.dialogImagen = true;
      // }

      // if (result.length > 1) {
      //   this.dialogComprobantesMultiples = true;
      //   this.result = result;
      // } else {
      //   this.dialogImagen = true;
      // }


    },

    verImagen2(imagen) {
      this.imagen = imagen;
      this.dialogVer = true;
    },

    eliminarRequisicion(value) {
      this.cargar = true;
      return this.$http
        .put("eleminar.partida/" + value.id)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    preAutorizar(estatus) {
      this.cargar = true;

      const payload = {
        id_usuario_autoriza_rechaza: this.getdatosUsuario.iderp,
        id_requisicion_compra_estatus: 5,
        id_requisicion: this.editedItem.id,
      };

      return this.$http
        .post("requisicion.preautorizar", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    updateEstatus(estatus) {
      this.cargar = true;

      const payload = {
        id_requisicion_compra_estatus: estatus,
        id_requisicion: this.editedItem.id,
      };

      return this.$http
        .post("update.estatus.requisicion", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    aceptarComprobante() {
      this.cargar = true;
      const ids = this.partidasSelected2.map((index) => {
        return index.id;
      });

      if (ids != "") {
        const payload = {
          id: ids,
        };
        return this.$http
          .post("update.aceptar.comprobante", payload)
          .then((response) => {

            for( const i in this.nomina ){

              if( ids.includes( this.nomina[i].id ) ){ this.nomina[i].estatus = 3 }

            }


            this.partidasSelected2 = [];
            this.validarSuccess(response.data.message);
            this.cargar = false;
            // this.initialize();
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      } else {
        let miArray = [];
        const payload = {
          id: this.idfoto.id,
        };
        return this.$http
          .post("update.aceptar.comprobante", payload)
          .then((response) => {
            this.partidasSelected2 = [];
            this.validarSuccess(response.data.message);
            this.cargar = false;

            for( const i in this.nomina ){

              if( this.nomina[i].id == this.idfoto.id ){ this.nomina[i].estatus = 3 }

            }
            // this.initialize();
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      }
    },

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },

    getBase64(file) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        me.vistaPrevia = {
          url: reader.result,
          image_name: file.name,
        };
      };
    },

    createFile(file) {
      if (file.size > 10000000) {
        alert("please check file size no over 5 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      this.archivos = file;
      this.getBase64(this.archivos);
      this.dragging = false;
    },

    removeFile() {
      this.vistaPrevia = null;
      this.archivos = null;
      this.file = "";
    },

    async pegar(event) {
      console.log(event.code);

      if (event.code == "KeyV") {
        const { type, value } = await Clipboard.read();

        // Validamos que sea de tipo imagen
        if (type == "image/png") {
          // Convertimos el base64 en una imagen
          const file = this.convertFile(value, "imagen.png");
          this.createFile(file);
        }
      }
    },

    convertFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    enviarMensajeRechazo() {
      this.cargar = true;
      const payload = {
        id_partida: this.idfoto.id,
        id: this.idfoto.id,
        mensaje: this.editedItem.mensaje,
      };
      return this.$http
        .post("add.egreso.mensaje", payload)
        .then((response) => {
          this.$http
            .post("update.estatus.partida.revision", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo = false;
              this.initialize();
              this.editedItem.mensaje = null;
              this.getMensajesRechazo();
              this.dialogImagen = false;
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    enviarMensajeRespuesta() {
      // Validamos que la cantidad se haya seleccionado correctamente
      if (this.item.respuestas == "") {
        return this.validarErrorDirecto("Favor de escribir la respuesta");
      }

      this.cargar = true;
      const payload = {
        id_partida: this.idfoto.id,
        respuesta: this.item.respuestas,
        id: this.idfoto.id,
      };

      return this.$http
        .post("update.mensaje.rechazo.respuesta", payload)
        .then((response) => {
          this.$http
            .post("update.estatus.partida", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo2 = false;
              this.initialize();
              this.item.respuestas = null;
              this.getMensajesRechazo();
              this.dialogImagen = false;
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    enviarMensajeRechazoMultiple() {
      this.cargar = true;
      const ids = this.partidasSelected2.map((index) => {
        return index.id;
      });
      const payload = {
        id_partida: ids,
        id: ids,
        mensaje: this.editedItem.mensaje,
      };
      return this.$http
        .post("add.egreso.mensaje", payload)
        .then((response) => {
          this.$http
            .post("update.estatus.partida.revision", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo_multiple = false;
              this.initialize();
              this.editedItem.mensaje = null;
              this.getMensajesRechazo();
              this.dialogImagen = false;
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    siguientePartida() {
      if (this.filterNomina.length == 1) {
        return;
      }
      const index = this.filterNomina.indexOf(this.idfoto);

      if (index < this.filterNomina.length) {
        this.idfoto = this.filterNomina[parseInt(index) + 1];
        this.verImagen(this.idfoto.fotosPago[0]);
        this.getMensajesRechazo(this.idfoto);
        this.getComentarioComprobante(this.idfoto);
      }
    },

    anteriorPartida() {
      if (this.filterNomina.length == 1) {
        return;
      }
      const index = this.filterNomina.indexOf(this.idfoto);

      if (index > 0) {
        this.idfoto = this.filterNomina[parseInt(index) - 1];
        this.verImagen(this.idfoto.fotosPago[0]);
        this.getMensajesRechazo(this.idfoto);
        this.getComentarioComprobante(this.idfoto);
      }
    },

    getMensajesRechazo() {
      this.cargar = true;
      this.mensajes = [];

      const payload = {
        id_partida: this.idfoto.id,
      };

      return this.$http
        .post("consultar.mensajes.rechazo.egreso", payload)
        .then((response) => {
          this.mensajes = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getHistorialEgreso() {
      this.cargar = true;
      this.historialEgreso = [];

      return this.$http
        .get("procesar.cambio.historial/" + this.idfoto.id)
        .then((response) => {
          this.historialEgreso = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    formatNumber(value) {
      if (value !== undefined && value !== null) {
        return value.toLocaleString("es-MX");
      } else {
        return "";
      }
    },

    updateMensaje(texto) {
      this.editedItem.mensaje = texto;
    },

    //Sube comprobantes a todos los seleccionados
    obtenerSeleccionados() {
      const seleccionados = this.partidasSelected;
      const informacion = seleccionados.map((item) => ({
        id: item.id,
      }));
      this.dialogSubirComprobante2 = true;
    },
    //-------------------------------------------------------------------------------------------------------------------------
    subirComprobantesMultiples() {
      var formData = new FormData();
      const seleccionados = this.partidasFinales;
      // const ids = seleccionados.map((index) => {
      //   return index.id;
      // });

      formData.append("file", this.file);
      formData.append("requi", this.partidasFinales);
      formData.append("id_partida", this.idtabla.id);
      formData.append("sueldo1", this.itemComprobante.sueldo_1);
      formData.append("sueldo2", this.itemComprobante.sueldo_2);
      formData.append("comisiones", this.itemComprobante.comisiones);
      formData.append("caja_de_ahorro", this.itemComprobante.caja_de_ahorro);
      formData.append("a_depositar", this.itemComprobante.a_depositar);
      formData.append("dep_1", this.itemComprobante.dep_1);
      formData.append("dep_2", this.itemComprobante.dep_2);
      formData.append("deuda", this.itemComprobante.deuda);
      formData.append("bonos", this.itemComprobante.bonos);
      formData.append("notas", this.itemComprobante.notas);
      // formData.append("id_usuario_subio"  , this.getdatosUsuario.iderp );
      // formData.append("tipo_pago"         , this.tipo_pago );
      console.log(this.partidasFinales)
      this.cargar = true;

      return this.$http
        .post("subir.comprobantes.partidas", formData)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.file = null;
          this.partidasSelected = [];
          this.partidasFinales = []
          this.vistaPrevia = null;
          this.cargar = false;
          this.dialogSubirComprobante2 = false;
          this.itemComprobante = Object.assign({}, this.itemComprobanteDefault);
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getComentarioComprobante() {
      this.cargar = true;
      this.comentarios = [];
      const payload = {
        id_partida: this.idfoto.id,
      };
      return this.$http
        .post("consultar.comentario.comprobante", payload)
        .then((response) => {
          this.comentarios = response.data;
          this.nota_comprobante = this.comentarios[0].notas;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getProyectos() {
      this.proyectos = [];
      return this.$http
        .get("consultar.proyectos")
        .then((response) => {
          this.proyectos = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    getProyectos() {
      this.ciclosegresos = [];
      return this.$http
        .get("consultar.ciclos.egresos")
        .then((response) => {
          this.ciclosegresos = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    toggleColumns() {
      this.columnsVisible = !this.columnsVisible;

      if (this.columnsVisible) {
        this.visibleHeaders = this.headers;
      } else {
        const columnsToToggle = ["id_requisicion_compra", "ciclo", "elemento_mantenimiento", "proyecto", "numero_cuenta"];
        this.visibleHeaders = this.headers.filter(
          (header) => !columnsToToggle.includes(header.value)
        );
      }
    },

    saveDialog ( value ) {
      this.cargar = true;

      const payload = {
        id_partida           : value.id,
        id_plantel_resultados: value.id_plantel_resultados,
      };

      return this.$http.post("update.plantel.egreso", payload).then((response) => {
        this.cargar = false;
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
        this.initialize()
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },

    cancelDialog () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },

    openDialog () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },

    closeDialog () {
      console.log('Dialog closed')
    },

    savePlanteles( ){
      this.cargar = true;

      const payload = {
        id_plantel    : this.id_plantelSeleccionado,
        requisiciones : this.partidasSelectedPlantel,
      };

      return this.$http.post("update.egresos.plntel", payload).then((response) => {
        this.cargar = false;
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
        this.id_plantelSeleccionado = null
        this.partidasSelectedPlantel = []
        this.initialize()
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },

  getAllPartidas() {
      this.partidaFotos = [];
      this.cargar = true;

      const payload = {
        nombre_foto:  this.idfoto.nombre_foto
      };

      return this.$http
        .post("consultar.partidas.foto", payload)
        .then((response) => {
          this.partidaFotos = response.data;
          this.cargar = false;

          this.dialogComprobantesMultiples = true

          if (this.dialogComprobantesMultiples) {    

            if (this.partidaFotos.length > 1) {
              this.partidaFotos = this.partidaFotos;
            } else {
              this.dialogImagen = true;
              this.dialogComprobantesMultiples = false;
            }

          }
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


  },
};
</script>
<style scoped>

  .editar{
    background-color: #FF3030 !important;
    color: white;
  }


.dropZone {
  width: 100%;
  height: 150px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px solid #d8d8d8;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #d8d8d8;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 80%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #d8d8d8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}

.tabla-menor-ancho {
  width: 950px; /* Ajusta el valor del ancho según tus necesidades */
}
</style>
